@import "@/assets/scss/style.scss";

.wrapperAddressForm {
    .row {
        display: flex;
        margin-bottom: 16px;

        &::v-deep {
            .ant-form-item-label {
                line-height: 1;

                & > label {
                    @extend .u-b2;
                    color: $grey-8;
                }
            }
        }

        & > *:nth-child(3) {
            flex: 1 0 50%;
        }

        & > * {
            flex: 1 1 50%;
            margin-bottom: 0;
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }
        }

        &--small {
            & > * {
                flex: 0 1 calc(50% - 16px);
            }
        }

        &--large {
            & > *:first-child {
                flex: 0 1 75%;
            }

            & > * {
                flex: 0 1 25%;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .textarea {
        width: 100%;
    }

    .divider {
        color: $grey-9;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}
