@import "@/assets/scss/style.scss";

.wrapperAdditionalAddressTopBar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;

    .actions {
        & > * {
            margin-right: 4px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
