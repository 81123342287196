@import "@/assets/scss/style.scss";

.wrapperAddressDetails {
    .row {
        display: flex;
        margin-bottom: 30px;
        align-items: flex-end;

        & > * {
            flex: 0 1 50%;
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 450px) {
    .wrapperAddressDetails .wrapper__name-and-address {
        flex-direction: column;
        align-items: flex-start;

        .wrapperTwoRowWithTag {
            margin-bottom: 1rem;
        }
    }
}
